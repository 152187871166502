<template>
  <div class="bg">
    <div>
      <van-dropdown-menu active-color="#3583f0" :overlay="overlay">
        <van-dropdown-item v-bind:class="{}"
                           :options="allOptionType" title="全部" @open="showAll" name="0" @close="showAll"/>
        <van-dropdown-item :title="title" v-model="menuType" :options="menuOptionType" @open="openOverlay" name="1" @change="changeTap" />
      </van-dropdown-menu>
    </div>
    <van-pull-refresh v-model="isLoading" :head-height="80" @refresh="changeTap">
      <div>
        <div style="margin:0 0 0 4%">
          <div class="title-height">
            <div class="title-num" style=";">共{{ all_reocrd_count }}条数据</div>
          </div>
        </div>
        <div class="list" v-if="serviceList.length>0">
          <van-list v-model="loading" :finished="finished" offset="0" finished-text="没有更多了" @load="getDataList">
            <div class="item" v-for="service in serviceList" @click="toDetail(service.status,service.id,service.checkStatus)" v-if="title==='状态'">
              <div class="head">
                <div v-if="service.status===20" style="background-color:#3480F0;" class="tag-status">
                  <div class="content">待服务</div>
                </div>
                <div v-if="service.status===51" style="background-color:#3480F0;" class="tag-status" >
                  <div class="content">待接单</div>
                </div>
                <div v-if="service.status===52" style="background-color:#ed4747;" class="tag-status">
                  <div class="content">审核不通过</div>
                </div>
                <div v-if="service.status===30" style="background-color:#ffa93a;" class="tag-status">
                  <div class="content">待评价</div>
                </div>
                <div v-if="service.status===40" style="background-color:#0ab9b3;" class="tag-status">
                  <div class="content">已评价</div>
                </div>
                <div v-if="service.status===50" style="background-color:#3480F0" class="tag-status">
                  <div class="content">待审核</div>
                </div>
                <div v-if="service.status===54" style="background-color:#3480F0;" class="tag-status">
                  <div class="content">已退单，待审核</div>
                </div>
                <div v-if="service.status===53" style="background-color:red;" class="tag-status">
                  <div class="content">居民撤销</div>
                </div>
                <div class="name">{{ service.name }}</div>
                <div v-if="service.status!==50">
                  <div class="benefit" v-if="service.serviceProfit===0">/(无偿)</div>
                  <div class="benefit" v-if="service.serviceProfit===1">/(低偿)</div>
                </div>

                <div class="typeName">{{ service.typeName }}</div>
              </div>
              <div style="display: flex;margin-top: 2%;">
                <van-image class="all-image" :src="require('@/assets/img/person.png')"></van-image>
                <div class="info">{{ service.userName }}</div>

              </div>
              <div style="display: flex;margin-top: 2%;">
                <van-image class="location-image" :src="require('@/assets/img/location.png')"></van-image>
                <div class="info">{{ service.fullName }}</div>
                <van-image class="goto-image" :src="require('@/assets/img/goto.png')"></van-image>
              </div>
              <div style="display: flex;margin-top: 2%;position:relative;">
                <van-image class="all-image" :src="require('@/assets/img/time.png')"></van-image>
                <div class="info">{{ service.createDate }}</div>

              </div>
            </div>
            <div class="item" v-for="service in serviceList" @click="toDetail(service.status,service.id,service.checkStatus)" v-if="title==='待审核' && service.status===50">
              <div class="head">
                <div v-if="service.status===50" style=";background-color:#3480F0;" class="tag-status">
                  <div class="content">待审核</div>
                </div>
                <div class="name">{{ service.name }}</div>
                <!--              <div class="benefit" v-if="service.serviceProfit===0">/(无偿)</div>-->
                <!--              <div class="benefit" v-if="service.serviceProfit===1">/(低偿)</div>-->
                <div class="typeName">{{ service.typeName }}</div>
              </div>
              <div style="display: flex;margin-top: 2%;">
                <van-image class="all-image" :src="require('@/assets/img/person.png')"></van-image>
                <div class="info">{{ service.userName }}</div>

              </div>
              <div style="display: flex;margin-top: 2%;">
                <van-image class="location-image" :src="require('@/assets/img/location.png')"></van-image>
                <div class="info">{{ service.fullName }}</div>
                <van-image class="goto-image" :src="require('@/assets/img/goto.png')"></van-image>
              </div>
              <div style="display: flex;margin-top: 2%;position:relative;">
                <van-image class="all-image" :src="require('@/assets/img/time.png')"></van-image>
                <div class="info">{{ service.createDate }}</div>

              </div>
            </div>
            <div class="item" v-for="service in serviceList" @click="toDetail(service.status,service.id,service.checkStatus)" v-if="title==='审核不通过' && service.status===52">
              <div class="head">
                <div v-if="service.status===52" style="background-color:#ed4747;" class="tag-status">
                  <div class="content">审核不通过</div>
                </div>
                <div class="name">{{ service.name }}</div>
                <div class="benefit" v-if="service.serviceProfit===0">/(无偿)</div>
                <div class="benefit" v-if="service.serviceProfit===1">/(低偿)</div>
                <div class="typeName">{{ service.typeName }}</div>
              </div>
              <div style="display: flex;margin-top: 2%;">
                <van-image class="all-image" :src="require('@/assets/img/person.png')"></van-image>
                <div class="info">{{ service.userName }}</div>

              </div>
              <div style="display: flex;margin-top: 2%;">
                <van-image class="location-image" :src="require('@/assets/img/location.png')"></van-image>
                <div class="info">{{ service.fullName }}</div>
                <van-image class="goto-image" :src="require('@/assets/img/goto.png')"></van-image>
              </div>
              <div style="display: flex;margin-top: 2%;position:relative;">
                <van-image class="all-image" :src="require('@/assets/img/time.png')"></van-image>
                <div class="info">{{ service.createDate }}</div>
              </div>
            </div>
            <div class="item" v-for="service in serviceList" @click="toDetail(service.status,service.id,service.checkStatus)" v-if="title==='待接单' && service.status===51">
              <div class="head">
                <div v-if="service.status===51" style="background-color:#666666;" class="tag-status">
                  <div class="content">待接单</div>
                </div>
                <div class="name">{{ service.name }}</div>
                <div class="benefit" v-if="service.serviceProfit===0">/(无偿)</div>
                <div class="benefit" v-if="service.serviceProfit===1">/(低偿)</div>
                <div class="typeName">{{ service.typeName }}</div>
              </div>
              <div style="display: flex;margin-top: 2%;">
                <van-image class="all-image" :src="require('@/assets/img/person.png')"></van-image>
                <div class="info">{{ service.userName }}</div>

              </div>
              <div style="display: flex;margin-top: 2%;">
                <van-image class="location-image" :src="require('@/assets/img/location.png')"></van-image>
                <div class="info">{{ service.fullName }}</div>
                <van-image class="goto-image" :src="require('@/assets/img/goto.png')"></van-image>
              </div>
              <div style="display: flex;margin-top: 2%;position:relative;">
                <van-image class="all-image" :src="require('@/assets/img/time.png')"></van-image>
                <div class="info">{{ service.createDate }}</div>

              </div>
            </div>
            <div class="item" v-for="service in serviceList" @click="toDetail(service.status,service.id,service.checkStatus)" v-if="title==='待评价' && service.status===30">
              <div class="head">
                <div v-if="service.status===30" style="background-color:#ffa93a;" class="tag-status">
                  <div class="content">待评价</div>
                </div>
                <div class="name">{{ service.name }}</div>
                <div class="benefit" v-if="service.serviceProfit===0">/(无偿)</div>
                <div class="benefit" v-if="service.serviceProfit===1">/(低偿)</div>
                <div class="typeName">{{ service.typeName }}</div>
              </div>
              <div style="display: flex;margin-top: 2%;">
                <van-image class="all-image" :src="require('@/assets/img/person.png')"></van-image>
                <div class="info">{{ service.userName }}</div>

              </div>
              <div style="display: flex;margin-top: 2%;">
                <van-image class="location-image" :src="require('@/assets/img/location.png')"></van-image>
                <div class="info">{{ service.fullName }}</div>
                <van-image class="goto-image" :src="require('@/assets/img/goto.png')"></van-image>
              </div>
              <div style="display: flex;margin-top: 2%;position:relative;">
                <van-image class="all-image" :src="require('@/assets/img/time.png')"></van-image>
                <div class="info">{{ service.createDate }}</div>
              </div>
            </div>
            <div class="item" v-for="service in serviceList" @click="toDetail(service.status,service.id,service.checkStatus)" v-if="title==='已评价' && service.status===40">
              <div class="head">
                <div v-if="service.status===40" style="background-color:#0ab9b3;" class="tag-status">
                  <div class="content">已评价</div>
                </div>

                <div class="name">{{ service.name }}</div>
                <div class="benefit" v-if="service.serviceProfit===0">/(无偿)</div>
                <div class="benefit" v-if="service.serviceProfit===1">/(低偿)</div>
                <div class="typeName">{{ service.typeName }}</div>
              </div>
              <div style="display: flex;margin-top: 2%;">
                <van-image class="all-image" :src="require('@/assets/img/person.png')"></van-image>
                <div class="info">{{ service.userName }}</div>

              </div>
              <div style="display: flex;margin-top: 2%;">
                <van-image class="location-image" :src="require('@/assets/img/location.png')"></van-image>
                <div class="info">{{ service.fullName }}</div>
                <van-image class="goto-image" :src="require('@/assets/img/goto.png')"></van-image>
              </div>
              <div style="display: flex;margin-top: 2%;position:relative;">
                <van-image class="all-image" :src="require('@/assets/img/time.png')"></van-image>
                <div class="info">{{ service.createDate }}</div>
              </div>
            </div>
            <div class="item" v-for="service in serviceList" @click="toDetail(service.status,service.id,service.checkStatus)" v-if="title==='待服务' && service.status===20">
              <div class="head">
                <div v-if="service.status===20" style="background-color:#3480F0;" class="tag-status">
                  <div class="content">待服务</div>
                </div>

                <div class="name">{{ service.name }}</div>
                <div class="benefit" v-if="service.serviceProfit===0">/(无偿)</div>
                <div class="benefit" v-if="service.serviceProfit===1">/(低偿)</div>
                <div class="typeName">{{ service.typeName }}</div>
              </div>
              <div style="display: flex;margin-top: 2%;">
                <van-image class="all-image" :src="require('@/assets/img/person.png')"></van-image>
                <div class="info">{{ service.userName }}</div>

              </div>
              <div style="display: flex;margin-top: 2%;">
                <van-image class="location-image" :src="require('@/assets/img/location.png')"></van-image>
                <div class="info">{{ service.fullName }}</div>
                <van-image class="goto-image" :src="require('@/assets/img/goto.png')"></van-image>
              </div>
              <div style="display: flex;margin-top: 2%;position:relative;">
                <van-image class="all-image" :src="require('@/assets/img/time.png')"></van-image>
                <div class="info">{{ service.createDate }}</div>
              </div>
            </div>
            <div class="item" v-for="service in serviceList" @click="toDetail(service.status,service.id,service.checkStatus)" v-if="title==='已退单，待审核' && service.status===54">
              <div class="head">
                <div v-if="service.status===54" style="background-color:#3480F0;" class="tag-status">
                  <div class="content">已退单，待审核</div>
                </div>
                <div class="name">{{ service.name }}</div>
                <div class="benefit" v-if="service.serviceProfit===0">/(无偿)</div>
                <div class="benefit" v-if="service.serviceProfit===1">/(低偿)</div>
                <div class="typeName">{{ service.typeName }}</div>
              </div>
              <div style="display: flex;margin-top: 2%;">
                <van-image class="all-image" :src="require('@/assets/img/person.png')"></van-image>
                <div class="info">{{ service.userName }}</div>

              </div>
              <div style="display: flex;margin-top: 2%;">
                <van-image class="location-image" :src="require('@/assets/img/location.png')"></van-image>
                <div class="info">{{ service.fullName }}</div>
                <van-image class="goto-image" :src="require('@/assets/img/goto.png')"></van-image>
              </div>
              <div style="display: flex;margin-top: 2%;position:relative;">
                <van-image class="all-image" :src="require('@/assets/img/time.png')"></van-image>
                <div class="info">{{ service.createDate }}</div>
              </div>
            </div>
            <div class="item" v-for="service in serviceList" @click="toDetail(service.status,service.id,service.checkStatus)" v-if="title==='居民退单' && service.status===53">
              <div class="head">
                <div v-if="service.status===53" style="background-color:red;" class="tag-status">
                  <div class="content">居民撤销</div>
                </div>
                <div class="name">{{ service.name }}</div>
                <div class="benefit" v-if="service.serviceProfit===0">/(无偿)</div>
                <div class="benefit" v-if="service.serviceProfit===1">/(低偿)</div>
                <div class="typeName">{{ service.typeName }}</div>
              </div>
              <div style="display: flex;margin-top: 2%;">
                <van-image class="all-image" :src="require('@/assets/img/person.png')"></van-image>
                <div class="info">{{ service.userName }}</div>

              </div>
              <div style="display: flex;margin-top: 2%;">
                <van-image class="location-image" :src="require('@/assets/img/location.png')"></van-image>
                <div class="info">{{ service.fullName }}</div>
                <van-image class="goto-image" :src="require('@/assets/img/goto.png')"></van-image>
              </div>
              <div style="display: flex;margin-top: 2%;position:relative;">
                <van-image class="all-image" :src="require('@/assets/img/time.png')"></van-image>
                <div class="info">{{ service.createDate }}</div>
              </div>
            </div>
          </van-list>
        </div>
        <van-empty description="暂无数据" v-else />
      </div>
    </van-pull-refresh>
  </div>

</template>

<script>
import topBar from '@/components/topBar/topBar'

export default {
  components :{
    topBar
  },
  mounted() {
    this.getDataList()

  },
  name: "older-service",
  data() {
    return {
      menuType:'',
      page:0,
      isLoading:false,
      loading:false,
      finished:false,
      temp_url:"http://uht.vaiwan.com/iapi/",
      orgId:this.$orgId,
      userId:this.$userId,
      active: 0,
      active_1:0,
      active_2:0,
      title:"状态",
      all_reocrd_count:0,
      allOptionType:[
        // { text: "全部", value: 0},
      ],
      menuOptionType: [
        { text: "待服务", value:20},
        { text: "待审核", value: 50 },
        { text: "审核不通过", value: 52 },
        { text: "待接单", value: 51 },
        { text: "待评价", value: 30 },
        { text: "已评价", value: 40 },
        { text: "已退单，待审核", value: 54 },
        { text: "居民撤销", value: 53 },
      ],
      menuOptionTypeArr:{
        0:"状态",
        20:"待服务",
        50:"待审核",
        52:"审核不通过",
        51:"待接单",
        30:"待评价",
        40:"已评价",
        54:"已退单，待审核",
        53:"居民撤销"
      },
      curType: 0,
      serviceList:[],
      overlay:false,
      childIndex: 1,
    }
  },
  methods: {
    openOverlay(){
      this.overlay = true
    },
    changeTap(value){
      // console.log(value)
      if (value!==0){
        this.title = this.menuOptionTypeArr[value]
      }else{
        this.title="状态"
        this.menuType = ''
      }
      this.all_reocrd_count=0
      this.serviceList = []
      this.page=0
      this.finished = false

      if(value !== undefined){
        this.curType = value
      }else{
        this.title = this.menuOptionTypeArr[this.curType]
      }
      setTimeout(() => {
        this.isLoading = false;
      }, 2000);
      this.getDataList(this.curType)
    },
    getDataList(type){
      var flag=''
      if(type!==0){
        flag=type
      }
      this.$httpApp({
        url: this.$httpApp.adornUrl("/wxapp/app/appserviceapply/list"),
        method: "post",
        params:this.$httpApp.adornParams({
          page:this.page++,
          limit:10,
          orgId:this.$orgId,
          status:flag,
          userId:this.$userId,
        }),
      }).then(({ data }) => {
        this.isLoading = false;
        if (data && data.code === 0) {
          data.page.list.forEach(item =>{
            this.serviceList.push(item)
          })
          this.all_reocrd_count+=data.page.list.length
          this.loading = false

          if(data.page.list.length < 10){
            this.finished = true
          }

        }
      });

    },
    toDetail(requestStatus,id,checkStatus){
      // console.log(checkStatus)
      this.$router.push({name:'request-detail-request',params:{requestStatus:requestStatus,id:id,checkStatus:checkStatus}})
    },
    newChange(value){
      console.log(this.serviceList)
      this.title=this.menuOptionTypeArr[value]
      console.log(value)
      var flag=0;
      for(var i=0;i<this.serviceList.length;++i){
        if(this.serviceList[i].status===value){
          flag++
        }
      }
      this.all_reocrd_count = flag
    },
    showAll(){
      this.overlay = false
      this.all_reocrd_count = this.serviceList.length
      this.changeTap(0)
    }
  }
}
</script>

<style scoped lang="scss" >
.list {
  font-size: 30px;
  padding: 30px 25px;

  .item {
    position: relative;
    width: 100%;
    background: #fff;
    padding: 30px 5px 30px 20px;
    box-sizing: border-box;
    border-radius: 10px;
    //margin: 0 15px 30px 15px;
    margin-bottom: 30px;
  }

  .van-ellipsis{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 15px;
  }
}

.head{
  display: flex;
  position: relative;
  line-height: 18px;


  .tag-status{
    transform: skewX(-20deg);
    font-size: 25px;
    padding: 3px 12px 0 13px;
    border-radius: 7px;
    font-weight: bold;
    color: white;
    .content{
      transform: skewX(20deg);
      line-height: 0.45rem;
    }



  }

  .name{
    margin-left: 0.25rem;
    font-size: 25px;
    line-height: 0.45rem;
  }
  .benefit{
    font-size: 25px;
    color: red;
    margin-top: 0.1rem;
  }

}

.typeName{
  font-size: 25px;
  color: #4589F6;
  margin-top: 15px;
  position:absolute;
  right: 2%
}
.info{
  display: flex;
  margin-top:0.5%;
  margin-left: 1%;
  font-size: 25px
}

.to-detail-btn{
  position: absolute;
  right: 5%;
  height:10px;
  width:6px;
}

.bg{
  background-color: #F5F5F5;
  min-height: 100vh;
  box-sizing: border-box;
}
.title-num{
  position:absolute;
  left:2%;
  display:inline-block;
  font-size: 25px;
  margin-top: 0.4rem
}

.goto-image{
  position: absolute;
  right: 5%;
  height:20px;
  width:12px;
}

.title-height{
  height: 40px;
  position: relative;
}

.all-image{
  height: 32px;
  width: 32px;
}

.location-image{
  height: 32px;
  width:26px;
  margin-left: 3px;
  margin-right: 3px;
}
.van-dropdown-menu ::v-deep .van-dropdown-menu__item:nth-child(1) .van-dropdown-menu__title{
  color: black !important;
}

.van-dropdown-menu ::v-deep .van-dropdown-menu__item:first-child .van-dropdown-menu__title::after{
  content: normal;
}
</style>

